import Container from 'src/components/_layout/Container/Container'
import * as Styles from './PartnersSlider.style'
import { CmsImage } from 'src/types'
import Text from 'src/components/atoms/Text/Text'
import Padder from 'src/components/_layout/Padder/Padder'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'src/components/atoms/Image/Image'
import SwiperCore, { Autoplay } from 'swiper'

type PartnersSliderProps = {
    title: string
    description: string
    gallery: Array<CmsImage>
}

SwiperCore.use([Autoplay])

const PartnersSlider: React.FC<PartnersSliderProps> = ({ title, description, gallery }) => {
    return (
        <Container>
            <Styles.Wrapper>
                <Padder top="xs">
                    <Text textVariant="heading4" dangerouslySetInnerHTML={{ __html: title }} />
                    <Text textVariant="base" dangerouslySetInnerHTML={{ __html: description }} />
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={30}
                        loop
                        autoplay={{ delay: 1 }}
                        speed={6000}
                        allowTouchMove={false}
                    >
                        {gallery.map(({ url, alt, id }) => (
                            <SwiperSlide key={id}>
                                <Image src={url} alt={alt} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Padder>
            </Styles.Wrapper>
        </Container>
    )
}

export default PartnersSlider
