import { CmsImage } from 'src/types'
import * as Styles from './GalleryGrid.style'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Image from 'src/components/atoms/Image/Image'

type GalleryGridProps = {
    title?: string
    description?: string
    images: Array<CmsImage>
    columnsNumber: 3 | 4 | 5 | 6
}

const GalleryGrid: React.FC<GalleryGridProps> = ({ title, description, images, columnsNumber }) => {
    const isSmall = columnsNumber > 3
    const isPartners = title === 'Main sponsor'
    return (
        <Styles.Wrapper as={Container}>
            <Padder top="sm">
                {!!title && <Text textVariant="heading4" dangerouslySetInnerHTML={{ __html: title }} />}
                {!!description && <RichText htmlString={description} textVariant="base" />}
                <FlexWrapper
                    justify={{ '@lg': 'start' }}
                    direction={{ '@lgDown': isSmall ? 'row' : 'column' }}
                    css={{
                        '@lg': { flexWrap: 'wrap', gap: '$xs' },
                        '@lgDown': {
                            flexWrap: isSmall ? 'wrap' : 'nowrap',
                            gap: '$xxs',
                        },
                    }}
                >
                    {images.map((image) => (
                        <Styles.ImageWrapper
                            key={image.id}
                            columns={columnsNumber}
                            css={
                                isPartners
                                    ? {
                                          '@mdDown': {
                                              img: {
                                                  display: 'block',
                                                  margin: 'auto',
                                                  maxWidth: '70%',
                                              },
                                          },
                                      }
                                    : undefined
                            }
                        >
                            <Image {...image} />
                        </Styles.ImageWrapper>
                    ))}
                </FlexWrapper>
            </Padder>
        </Styles.Wrapper>
    )
}

export default GalleryGrid
