import Container from 'src/components/_layout/Container/Container'
import * as Styles from './FeaturedArtists.style'
import Text from 'src/components/atoms/Text/Text'
import Content from '../Content/Content'
import { PostPreview } from 'src/types'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import PostPreviewWithImage from 'src/components/organisms/PostPreviewWithImage/PostPreviewWithImage'

type FeaturedArtistsProps = {
    title?: string
    description?: string
    artists: Array<Required<PostPreview>>
}

const FeaturedArtists: React.FC<FeaturedArtistsProps> = ({ title, description, artists }) => {
    return (
        <div>
            {(title || description) && <Content title={title || ''} description={description || ''} />}
            <Container>
                <FlexWrapper css={{ flexWrap: 'wrap', rowGap: '$sm', columnGap: '$sm' }} justify="start">
                    {artists.map((artist) => (
                        <Styles.ArtistItem key={artist.id}>
                            <PostPreviewWithImage {...artist} />
                        </Styles.ArtistItem>
                    ))}
                </FlexWrapper>
            </Container>
        </div>
    )
}

export default FeaturedArtists
