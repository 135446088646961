import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {})

export const ImageWrapper = styled('div', {
    width: '100%',
    ratio: {
        w: 465,
        h: 616,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
})

export const Title = styled('div', {
    position: 'absolute',
    bottom: '$xs',
    left: '$xs',
    color: '$white',
    width: '85%',
    lineHeight: 1.25,
})
