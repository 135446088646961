import { useRef } from 'react'
import arrow from 'src/assets/img/arrow.svg'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Image from 'src/components/atoms/Image/Image'
import Text from 'src/components/atoms/Text/Text'
import PostPreviewWithImage from 'src/components/organisms/PostPreviewWithImage/PostPreviewWithImage'
import { PostPreview } from 'src/types'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavButton, StyledArtistsSlider } from './ArtistsSlider.style'
import { breakpoints } from 'src/styles/stitches.config'

type ArtistsSliderProps = {
    artists: Array<Required<PostPreview>>
    pageId?: number
}

const ArtistsSlider: React.FC<ArtistsSliderProps> = ({ artists, pageId }) => {
    const swiperRef = useRef<SwiperInstance>()
    return (
        <div>
            <Padder top={{ '@initial': 'sm', '@md': 'lg' }}>
                <Container>
                    <FlexWrapper
                        direction={{ '@initial': 'column', '@md': 'row' }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        align={{ '@initial': 'center', '@md': 'start' }}
                    >
                        <Padder top="sm">
                            <Text as="h4" textVariant="heading4">
                                Altri artisti
                            </Text>
                        </Padder>
                        <FlexWrapper
                            as={Padder}
                            left={{ '@initial': 'zero', '@md': 'sm' }}
                            top={{ '@initial': 'sm', '@md': 'zero' }}
                            align="center"
                        >
                            <FlexWrapper css={{ gap: '1rem', fontSize: '2rem' }} align="center">
                                <NavButton
                                    type="button"
                                    onClick={() => swiperRef.current?.slidePrev()}
                                    css={{ img: { transform: 'rotate(180deg)' } }}
                                >
                                    <Image url={arrow} alt="scroll left" />
                                </NavButton>
                                <NavButton type="button" onClick={() => swiperRef.current?.slideNext()}>
                                    <Image url={arrow} alt="scroll right" />
                                </NavButton>
                            </FlexWrapper>
                        </FlexWrapper>
                    </FlexWrapper>
                </Container>
                <StyledArtistsSlider
                    as={Swiper}
                    slidesPerView={1.5}
                    spaceBetween={30}
                    loop
                    centeredSlides
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper
                    }}
                    breakpoints={{
                        [breakpoints.md]: {
                            slidesPerView: 3.5,
                            spaceBetween: 80,
                        },
                    }}
                >
                    {artists
                        .filter(({ id }) => id !== pageId)
                        .map((artist) => (
                            <SwiperSlide key={artist.id}>
                                <PostPreviewWithImage {...artist} forceReveal fullWidth />
                            </SwiperSlide>
                        ))}
                </StyledArtistsSlider>
            </Padder>
        </div>
    )
}

export default ArtistsSlider
