import { styled } from 'src/styles/stitches.config'

export const StyledMainMenutem = styled('div', {
    display: 'inline-block',
    position: 'relative',

    '> span': {
        cursor: 'pointer',
    },

    variants: {
        withLine: {
            true: {
                '&::after': {
                    position: 'absolute',
                    bottom: -2,
                    left: 0,
                    backgroundColor: '$primary',
                    width: 0,
                    height: 1,
                    transition: 'width .4s ease-out',
                    content: '',
                },
                '@hover': {
                    '&:hover::after': {
                        width: '100%',
                    },
                },
            },
        },
    },
})

export const StyledItemWrapper = styled('div', {
    position: 'relative',
})

export const StyledChildrenWrapper = styled('div', {
    position: 'absolute',
    top: '98%',
    paddingTop: '$xxs',
    paddingBottom: '$xxs',
    left: 0,
    width: '100%',
    '> a': {
        display: 'inline-block',
    },
})
