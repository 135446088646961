import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {})

export const Content = styled('div', {
    textAlign: 'justify',
    '@lg': {
        width: 'calc($colWidth * 12)',
    },
})

export const Image = styled('div', {
    '@lg': {
        textAlign: 'right',
        width: 'calc($colWidth * 5)',
    },
})
