import Image from 'src/components/atoms/Image/Image'
import { CmsImage, CmsLink } from 'src/types'
import { BannerWrapper, StyledImageWrapper } from './Banner.style'

const Banner: React.FC<{ image: CmsImage; link: CmsLink }> = ({ image: { url, alt }, link }) => {
    return (
        <BannerWrapper as={!!link ? 'a' : 'div'} href={link?.url || undefined} target={link?.target || undefined}>
            <StyledImageWrapper>
                <Image src={url} alt={alt} />
            </StyledImageWrapper>
        </BannerWrapper>
    )
}

export default Banner
