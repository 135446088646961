import { useResponsive } from 'ahooks'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import Logo from 'src/components/atoms/Logo/Logo'
import Text from 'src/components/atoms/Text/Text'
import LanguageSwitcher from 'src/components/molecules/LanguageSwitcher/LanguageSwitcher'
import { getLayerById } from 'src/selectors/layers'
import { toggleLayer } from 'src/state/layers'
import { RootState } from 'src/store'
import MainMenu from '../MainMenu/MainMenu'
import { StyledHeader } from './Header.style'
const Header: React.FC = (props) => {
    const dispatch = useDispatch()
    const responsiveInfo = useResponsive()
    const menuLayer = useSelector((state: RootState) =>
        getLayerById(state, 'mainMenu')
    )

    const toggleMenu = useCallback(() => {
        if (menuLayer) {
            dispatch(
                toggleLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    return (
        <StyledHeader id="header">
            <Container as={FlexWrapper} justify="between" align="center">
                <Logo />
                <FlexWrapper as={Padder} left="colWidth" align="center">
                    {responsiveInfo.md && (
                        <>
                            <MainMenu />
                            <LanguageSwitcher />
                        </>
                    )}
                    {!responsiveInfo.md && (
                        <div>
                            <Button onClick={toggleMenu}>
                                <Text
                                    textVariant="base"
                                    css={{
                                        fontWeight: '500',
                                        letterSpacing: '0.05rem',
                                    }}
                                >
                                    MENU
                                </Text>
                            </Button>
                        </div>
                    )}
                </FlexWrapper>
            </Container>
        </StyledHeader>
    )
}

export default Header
