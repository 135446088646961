import { CmsImage } from 'src/types'
import * as Styles from './TitleWithImage.style'
import marker from 'src/assets/img/marker.png'
import PageTitle from '../PageTitle/PageTitle'
import Image from 'src/components/atoms/Image/Image'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import { SlLocationPin } from 'react-icons/sl'
type TitleWithImageProps = {
    title: string
    subtitle: string
    image: CmsImage
    location: string
}

const TitleWithImage: React.FC<TitleWithImageProps> = ({ title, subtitle, image, location }) => {
    return (
        <div>
            <PageTitle title={title} css={{ lineHeight: 1.05 }} />
            <Container>
                <Styles.ImageWrapper>
                    <Styles.Subtitle>
                        <Text textVariant="heading2">{subtitle}</Text>
                    </Styles.Subtitle>
                    <Image src={image.url} alt={image.alt} />
                    {!!location && (
                        <Styles.Location>
                            <Padder top="xxs">
                                <SlLocationPin size="2rem" />
                                <Text
                                    textVariant="small"
                                    dangerouslySetInnerHTML={{
                                        __html: location,
                                    }}
                                />
                            </Padder>
                        </Styles.Location>
                    )}
                </Styles.ImageWrapper>
            </Container>
        </div>
    )
}

export default TitleWithImage
