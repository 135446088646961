import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0!important',
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',

    '@mdDown': {
        height: '60vh',
    },
})
