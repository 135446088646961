import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    '.swiper-wrapper': {
        'transition-timing-function': 'linear !important',
    },
    '.swiper-slide': {
        width: 'auto',
        '@mdDown': {
            maxWidth: 150,
        },
    },
})
