import { styled } from 'src/styles/stitches.config'

export const StyledArtistsSlider = styled('div', {
    position: `relative`,
    '.swiper-slide': {
        width: '100%',
        transition: 'opacity .3s linear',
        height: 'auto',
    },
})

export const NavButton = styled('button', {
    img: {
        width: '100px',
        height: 'auto',
    },
})
