import { useSelector } from 'react-redux'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import { getSettingsComponents } from 'src/selectors/settings'

const GlobalComponent: React.FC<{ globalComponent: string }> = ({
    globalComponent,
}) => {
    const components = useSelector(getSettingsComponents)
    return !!components[globalComponent] ? (
        <Composer components={[components[globalComponent]]} />
    ) : (
        <>{`Component ${globalComponent} not found`}</>
    )
}

export default GlobalComponent
