import { API_URL } from 'src/constants'

export const fetchSettingsFromServer = async () => {
    const response = await fetch(`${API_URL}/app`)
    return response.json()
}

export const fetchMenuFromServer = async () => {
    const response = await fetch(`${API_URL}/menu`)
    return response.json()
}
