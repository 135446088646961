import { Link as RRLink } from 'react-router-dom'
import { CmsLink } from 'src/types'

type LinkProps = Omit<CmsLink, 'title'> & {
    children: JSX.Element | string
    title?: string
}
function Link(props: LinkProps) {
    const { url: to, target, children, title } = props
    const isExternal = to.startsWith('http') || target === '_blank'

    return isExternal ? (
        <a href={to} target="_blank" title={title} rel="noreferrer">
            {children}
        </a>
    ) : (
        <RRLink to={to} title={title}>
            {children}
        </RRLink>
    )
}
export default Link
