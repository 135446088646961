import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import Scrollbar from 'react-smooth-scrollbar'
import { ScrollbarContext } from 'src/context/scrollbar'

import { StyledGlobalScroller } from './GlobalScroller.style'
import { useSelector } from 'react-redux'
import { getSettings } from 'src/selectors/settings'

const GlobalScroller: React.FC<PropsWithChildren<any>> = ({ children }) => {
    const { scrollbar } = useContext(ScrollbarContext)
    const location = useLocation()
    const timeoutRef = useRef<any>()
    const { background } = useSelector(getSettings)
    useEffect(() => {
        if (location.hash.length > 1) {
            if (timeoutRef.current) {
                global.clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                scrollbar.current.scrollbar.scrollTo(0, 0, 700)
            }, 2500)
        } else {
            scrollbar.current.scrollbar.scrollTo(0, 0, 0)
        }
    }, [scrollbar, location])

    return (
        <StyledGlobalScroller
            //@ts-ignore
            as={Scrollbar}
            ref={scrollbar}
            css={{
                '.scroll-content': { backgroundImage: `url(${background})` },
            }}
        >
            {children}
        </StyledGlobalScroller>
    )
}

export default GlobalScroller
