import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text/Text'

type LangPairObject = { value: string }
const langs = ['it', 'en']

function GTranslateGetCurrentLang() {
    var keyValue = document['cookie'].match('(^|;) ?googtrans=([^;]*)(;|$)')
    return keyValue ? keyValue[2].split('/')[2] : null
}

export function isLangPairObject(val: unknown): val is LangPairObject {
    if (typeof val === 'object' && !!val) {
        return 'value' in val
    }

    return false
}

const LanguageSwitcher: React.FC = (props) => {
    const translateELementRef = useRef() as React.MutableRefObject<HTMLDivElement>

    useEffect(() => {
        const script = document.createElement('script')

        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2'
        script.async = true

        document.body.appendChild(script)
        script.addEventListener('load', () => {
            setTimeout(() => {
                //@ts-ignore
                new google.translate.TranslateElement(
                    { pageLanguage: 'it', autoDisplay: false },
                    'google_translate_element2'
                )
            }, 1000)
        })
    }, [])

    const doGTranslate = useCallback((langPair: string | LangPairObject) => {
        let lang_pair: string = ''
        if (isLangPairObject(langPair)) {
            lang_pair = langPair.value
        } else if (typeof langPair === 'string') {
            lang_pair = langPair
        }
        if (!lang_pair) return

        const lang = lang_pair.split('|')[1]

        if (GTranslateGetCurrentLang() === null && lang === lang_pair.split('|')[0]) {
            return
        }

        const teCombo = document.querySelector<HTMLSelectElement>('[class^="goog-te-combo"][class$="goog-te-combo"]')
        if (!!teCombo && teCombo.innerHTML) {
            teCombo.value = lang
            teCombo.addEventListener('change', () => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                teCombo.dispatchEvent
            })
            teCombo.dispatchEvent(new Event('change'))
        }
    }, [])

    const changeLanguageHandler = useCallback((lang) => {
        doGTranslate(`it|${lang}`)
    }, [])

    return (
        <div>
            <div id="google_translate_element2" style={{ display: 'none' }} ref={translateELementRef}></div>
            <FlexWrapper css={{ gap: '0.5rem' }} className="notranslate">
                {langs.map((lang, index) => (
                    <Fragment key={index}>
                        <Text
                            as="button"
                            type="button"
                            onClick={() => changeLanguageHandler(lang)}
                            textVariant="menu"
                            textTransform="uppercase"
                        >
                            {lang}
                        </Text>
                        {index < langs.length - 1 && <span>|</span>}
                    </Fragment>
                ))}
            </FlexWrapper>
        </div>
    )
}

export default LanguageSwitcher
