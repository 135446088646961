import Text from '../Text/Text'
import { StyledRichText } from './RichText.style'

type RichTextProps = {
    htmlString: string
    textVariant?: string
}
const RichText: React.FC<RichTextProps> = ({
    htmlString,
    textVariant = 'base',
}) => {
    return (
        //@ts-ignore
        <Text textVariant={textVariant}>
            <StyledRichText dangerouslySetInnerHTML={{ __html: htmlString }} />
        </Text>
    )
}

export default RichText
