import { styled } from 'src/styles/stitches.config'

export const StyledGlobalScroller = styled('div', {
    height: '100vh',
    '.scrollbar-track': {
        zIndex: 111,
    },

    '.scroll-content': {
        backgroundSize: '65% auto',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',

        '@mdDown': {
            backgroundSize: 'auto 80vh',
            backgroundPosition: 'top center',
        },
    },
})
