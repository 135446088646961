import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchMenuFromServer, fetchSettingsFromServer } from 'src/api/settings'

import { FooterType, SettingsSliceState } from './types'

const initialState: SettingsSliceState = {
    ready: false,
    slugs: {},
    logo: '',
    background: '',
    language: 'en',
    languages: {},
    ajaxurl: '',
    components: {} as Record<string, any>,
    footer: {} as FooterType,
    labels: {},
    menu: [],
} as SettingsSliceState

export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async () => {
        const response = await fetchSettingsFromServer()
        return response
    }
)

export const fetchMenu = createAsyncThunk('settings/fetchMenu', async () => {
    const response = await fetchMenuFromServer()
    return response
})

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            return {
                ...state,
                ready: true,
                ...action.payload,
            }
        })
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            return {
                ...state,
                menu: action.payload,
            }
        })
    },
})

export default settingsSlice.reducer
