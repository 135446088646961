import { useSelector } from 'react-redux'
import { getSettingsComponents } from 'src/selectors/settings'
import * as Styles from './CommonFooter.style'
import Composer from 'src/components/helpers/PageComposer/PageComposer'

type CommonFooterProps = {}

const CommonFooter: React.FC<CommonFooterProps> = ({}) => {
    const components = useSelector(getSettingsComponents)
    return (
        <>
            <Composer
                components={[
                    components['TicketsBanner'],
                    components['PartnersSlider'],
                ]}
            />
        </>
    )
}

export default CommonFooter
