import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import * as Styles from './DoubleColumnText.style'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import RichText from 'src/components/atoms/RichText/RichText'

type DoubleColumnTextProps = {
    firstColumn: string
    secondColumn: string
}

const DoubleColumnText: React.FC<DoubleColumnTextProps> = ({ firstColumn, secondColumn }) => {
    return (
        <Container>
            <FlexWrapper
                direction={{ '@initial': 'column', '@lg': 'row' }}
                css={{ gap: '$sm' }}
                justify={{ '@lg': 'between' }}
            >
                <Styles.Wrapper>
                    <RichText htmlString={firstColumn} textVariant="base" />
                </Styles.Wrapper>
                <Styles.Wrapper>
                    <RichText htmlString={secondColumn} textVariant="base" />
                </Styles.Wrapper>
            </FlexWrapper>
        </Container>
    )
}

export default DoubleColumnText
