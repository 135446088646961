import { CmsImage } from 'src/types'
import { NavButton, StyledGallery } from './Gallery.style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperInstance from 'swiper'
import { useRef } from 'react'
import Image from 'src/components/atoms/Image/Image'
import Padder from 'src/components/_layout/Padder/Padder'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import arrow from 'src/assets/img/arrow.svg'

type GalleryProps = {
    images: Array<CmsImage>
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const swiperRef = useRef<SwiperInstance>()
    return (
        <Padder top={{ '@initial': 'sm', '@md': 'lg' }}>
            <Container>
                <FlexWrapper
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    justify={{ '@initial': 'start', '@md': 'between' }}
                    align={{ '@initial': 'center', '@md': 'start' }}
                >
                    <Padder top="sm">
                        <Text as="h4" textVariant="heading4">
                            Gallery
                        </Text>
                    </Padder>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'sm' }}
                        top={{ '@initial': 'sm', '@md': 'zero' }}
                        align="center"
                    >
                        <FlexWrapper
                            css={{ gap: '1rem', fontSize: '2rem' }}
                            align="center"
                        >
                            <NavButton
                                type="button"
                                onClick={() => swiperRef.current?.slidePrev()}
                                css={{ img: { transform: 'rotate(180deg)' } }}
                            >
                                <Image url={arrow} alt="scroll left" />
                            </NavButton>
                            <NavButton
                                type="button"
                                onClick={() => swiperRef.current?.slideNext()}
                            >
                                <Image url={arrow} alt="scroll right" />
                            </NavButton>
                        </FlexWrapper>
                    </FlexWrapper>
                </FlexWrapper>
            </Container>
            <StyledGallery
                as={Swiper}
                slidesPerView="auto"
                spaceBetween={80}
                loop
                centeredSlides
                onSwiper={(swiper) => {
                    swiperRef.current = swiper
                }}
            >
                {images.map((image) => (
                    <SwiperSlide key={image.id}>
                        <Image {...image} />
                    </SwiperSlide>
                ))}
            </StyledGallery>
        </Padder>
    )
}

export default Gallery
