import { breakpoints, styled, theme } from 'src/styles/stitches.config'
import { fluidSize } from 'src/styles/styleUtils'

const Text = styled('div', {
    fontFamily: '$primary',
    fontSize: '1rem',
    fontWeight: '$normal',
    variants: {
        color: Object.fromEntries(Object.keys(theme.colors).map((colorKey) => [colorKey, { color: `$${colorKey}` }])),
        align: {
            center: { textAlign: 'center' },
            right: { textAlign: 'right' },
            left: { textAlign: 'left' },
            justify: { textAlign: 'justify' },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
        },
        textVariant: {
            heading1: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                lineHeight: 0.85,
                fontSize: '56px',
                '@sm': {
                    fontSize: fluidSize(56, 140, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '140px',
                },
            },
            heading2: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                fontSize: '40px',
                '@sm': {
                    fontSize: fluidSize(40, 70, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '70px',
                },
            },
            heading3: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                fontSize: '34px',
                '@sm': {
                    fontSize: fluidSize(34, 83, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '83px',
                },
            },
            heading4: {
                fontFamily: 'Arial GEO',
                fontSize: '2.5rem',
                fontWeight: '$bold',
            },
            subheading4: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                fontSize: '17px',
                '@sm': {
                    fontSize: fluidSize(17, 48, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '48px',
                },
            },
            title: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                fontSize: '27px',
                '@sm': {
                    fontSize: fluidSize(27, 40, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '45px',
                },
            },
            titleSmall2: {
                fontFamily: 'Arial GEO',
                fontWeight: '$bold',
                fontSize: '20px',
                '@sm': {
                    fontSize: fluidSize(20, 30, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '30px',
                },
            },
            titleSmall: {
                fontFamily: 'Arial GEO',
                fontSize: '1.4rem',
                fontWeight: '$bold',
                '@md': {
                    fontSize: '1.8rem',
                },
            },
            base: {
                lineHeight: '1.2',
                fontSize: '16px',
                '@sm': {
                    fontSize: fluidSize(16, 22, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '22px',
                },
            },
            base2: {
                lineHeight: '1.2',
                fontSize: '14px',
                '@sm': {
                    fontSize: fluidSize(14, 20, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '20px',
                },
            },
            menu: {
                fontFamily: 'Arial GEO',
                lineHeight: '1.2',
                fontSize: '14px',
                fontWeight: '$bold',
                '@sm': {
                    fontSize: fluidSize(14, 18, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '18px',
                },
            },
            strongBase: {
                fontFamily: 'Arial GEO',
                lineHeight: '1.2',
                fontSize: '16px',
                fontWeight: '$bold',
                '@sm': {
                    fontSize: fluidSize(16, 22, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '22px',
                },
            },
            strongSmall: {
                fontSize: '1rem',
                lineHeight: '1.2',
                fontWeight: '$medium',
            },
            subtitle: {
                fontSize: '16px',
                lineHeight: 1.2,
                '@sm': {
                    fontSize: fluidSize(16, 27, breakpoints.sm, breakpoints.xxl),
                },
                '@xxl': {
                    fontSize: '27px',
                },
            },
            small: {
                fontSize: '1rem',
                lineHeight: '1.3',
            },
        },
    },
})

export default Text
