import Container from 'src/components/_layout/Container/Container'
import * as Styles from './EventsListing.style'
import Text from 'src/components/atoms/Text/Text'
import { PostPreview } from 'src/types'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import { useLocation } from 'react-router-dom'
import Link from 'src/components/helpers/Link/Link'

type EventsListingProps = {
    title: string
    events: Array<PostPreview>
    pageId?: number
}

const EventsListing: React.FC<EventsListingProps> = ({ title, events, pageId }) => {
    const location = useLocation()
    return (
        <Container>
            <Padder top="sm">
                <Text textVariant="heading4" dangerouslySetInnerHTML={{ __html: title }} />

                <Padder top="xs">
                    {events
                        .filter(({ link }) => !location.pathname.includes(link.url))
                        .map(({ link, excerpt /*id*/ }, index) => (
                            <Styles.EventWrapper key={index}>
                                <RichText htmlString={excerpt} />
                                <Link {...link}>
                                    <Text
                                        textVariant="base2"
                                        css={{
                                            fontWeight: '$bold',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {link.title}
                                    </Text>
                                </Link>
                            </Styles.EventWrapper>
                        ))}
                </Padder>
            </Padder>
        </Container>
    )
}

export default EventsListing
