import { styled } from 'src/styles/stitches.config'

const Button = styled('button', {
    display: 'inline-block',
    border: 'solid 2px $black',
    borderRadius: '999px',
    width: 'auto',
    padding: '$xxs $xs',
    fontWeight: '$bold',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    variants: {
        variant: {
            big: {
                fontSize: '1.2rem',
                lineHeight: 1,
                borderWidth: 3,
                '@lg': {
                    fontSize: '2rem',
                },
                '@md': {
                    fontSize: '1.8rem',
                },
            },
        },
        color: {
            brand: {
                borderColor: '$brand',
                color: '$brand',
            },
        },
    },
})

export default Button
