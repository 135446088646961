import { BASEPATH } from 'src/constants'
import NotFound from 'src/containers/NotFound'
import Page from 'src/containers/Page'

const getRoutes = (slugs: Record<string, string>) => {
    return [
        {
            key: 'homepage',
            component: Page,
            exact: true,
            path: `${BASEPATH}`,
        },
        {
            key: 'page',
            component: Page,
            path: `${BASEPATH}${slugs.pages}`,
        },
        {
            key: '404',
            component: NotFound,
        },
    ]
}

export default getRoutes
