import { CmsImage, CmsLink } from 'src/types'
import * as Styles from './LocationBanner.style'
import Button from 'src/components/atoms/Button/Button'
import Link from 'src/components/helpers/Link/Link'
type LocationsBannerProps = {
    image: CmsImage
    link: CmsLink
}
const LocationBanner: React.FC<LocationsBannerProps> = ({ image, link }) => {
    return (
        <Styles.Wrapper css={{ backgroundImage: `url(${image.url})` }}>
            <Link {...link}>
                <Button color="brand" variant="big">
                    {link.title}
                </Button>
            </Link>
        </Styles.Wrapper>
    )
}

export default LocationBanner
