import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'

type ContentProps = {
    title: string
    subtitle?: string
    description: string
    padded?: boolean
}

const Content: React.FC<ContentProps> = ({
    title,
    subtitle,
    description,
    padded,
}) => {
    return (
        <Container css={padded ? { paddingTop: '$xxxl' } : undefined}>
            <Revealer style={{ width: '100%' }}>
                <Padder top="sm">
                    <Text
                        textVariant="heading4"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {!!subtitle && (
                        <Text
                            textVariant="strongBase"
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                        />
                    )}
                    <RichText htmlString={description} textVariant="base" />
                </Padder>
            </Revealer>
        </Container>
    )
}

export default Content
