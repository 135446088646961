import { createStitches } from '@stitches/react'

import styleUtils from './styleUtils'

export const styleConstants = {
    colWidth: 4.16666666667,
}

export const breakpoints: Record<string, number> = {
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1440,
    xxl: 1600,
}

const spaces = {
    colWidth: `${100 / 24}vw`,
    xxxs: '5px',
    xxs: '10px',
    xs: '30px',
    sm: '60px',
    lg: '90px',
    xl: '150px',
    xxl: '200px',
    zero: '0px',
}

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
    theme: {
        fonts: {
            primary: 'ArialMT',
        },
        colors: {
            primary: '#222222',
            primary_light: '#888888',
            black: '#000000',
            white: '#ffffff',
            whiteSmoke: '#e1e1e1',
            brand: '#fdeae2',
        },
        fontWeights: {
            normal: 'normal',
            medium: '500',
            light: '200',
            bold: 'bold',
        },
        sizes: spaces,
        space: spaces,
    },
    media: {
        ...Object.fromEntries([
            ...Object.keys(breakpoints).map((key: string) => [key, `(min-width:${breakpoints[key]}px)`]),
            ...Object.keys(breakpoints).map((key: string) => [`${key}Down`, `(max-width:${breakpoints[key] - 1}px)`]),
        ]),
        hover: '(hover: hover)',
    },
    utils: {
        ...styleUtils,
    },
})

export const globalStyles = globalCss({
    body: {
        fontFamily: 'ArialMT',
        fontWeight: 'normal',
        top: '0!important',
        fontVariantLigatures: 'none',
        '-webkit-font-smoothing': 'antialiased',
    },

    '#root': {
        backgroundColor: '$brand',
    },
    '.App': {
        height: '100vh',
        backgroundColor: '$brand',
    },
    '.rotated-arrow': {
        transform: 'rotate(180deg)',
    },

    '.hidden-xs': {
        '@smDown': {
            display: 'none !important',
        },
    },
    '.hidden-sm': {
        '@mdDown': {
            display: 'none !important',
        },
    },
    '.visible-sm': {
        display: 'none !important',
        '@mdDown': {
            display: 'block !important',
        },
    },
    '.with-line': {
        textTransform: 'uppercase',
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        '&::after': {
            width: `${1 * styleConstants.colWidth}vw`,
            height: '2px',
            content: '',
            backgroundColor: '$black',
        },
    },
    '.skiptranslate': { display: 'none!important' },
})
