import { useRef } from 'react'
import Link from 'src/components/helpers/Link/Link'

import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import { breakpoints } from 'src/styles/stitches.config'
import { PostPreview } from 'src/types'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as Styles from './PostSlider.style'
import arrow from 'src/assets/img/arrow.svg'
import Image from 'src/components/atoms/Image/Image'

type PostSliderProps = {
    lastPosts: Array<PostPreview>
}

const PostSlider: React.FC<PostSliderProps> = ({ lastPosts }) => {
    const swiperRef = useRef<SwiperInstance>()
    return (
        <Container>
            <Styles.NavButton type="button" onClick={() => swiperRef.current?.slideNext()}>
                <Image url={arrow} alt="scroll left" />
            </Styles.NavButton>
            <Styles.Wrapper>
                <Swiper
                    loop
                    slidesPerView="auto"
                    spaceBetween={30}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper
                    }}
                    breakpoints={{
                        [breakpoints.md]: {
                            spaceBetween: 120,
                        },
                    }}
                >
                    {lastPosts.map(({ title, excerpt, link, id }, index) => (
                        <SwiperSlide key={id}>
                            <Padder top="xs">
                                <Text textVariant="titleSmall" dangerouslySetInnerHTML={{ __html: title }} />
                                <RichText htmlString={excerpt} textVariant="base2" />
                                <Link {...link}>
                                    <Text textVariant="strongBase">{link.title}</Text>
                                </Link>
                            </Padder>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Styles.Wrapper>
        </Container>
    )
}

export default PostSlider
