import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useResponsive } from 'ahooks'
import { motion } from 'framer-motion'
import { getLayerById } from 'src/selectors/layers'
import { createLayer, hideLayer } from 'src/state/layers'
import { RootState } from 'src/store'

import { Link } from 'react-router-dom'
import Text from 'src/components/atoms/Text/Text'
//import SiteLinks from 'src/components/molecules/SiteLinks/SiteLinks'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getSettings } from 'src/selectors/settings'
import { MenuPanel, SocialWrapper, StyledLayerMenu } from './LayerMenu.style'
import Scrollbar from 'react-smooth-scrollbar'

const LAYER_ID = 'mainMenu'

const LayerMenu: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const dispatch = useDispatch()
    const responsiveInfo = useResponsive()
    const layer = useSelector((state: RootState) => getLayerById(state, LAYER_ID))

    const { menu } = useSelector(getSettings)

    const menuLayer = useSelector((state: RootState) => getLayerById(state, 'mainMenu'))
    const closeMenu = useCallback(() => {
        if (menuLayer && menuLayer.isActive) {
            dispatch(
                hideLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    useEffect(() => {
        dispatch(
            createLayer({
                id: LAYER_ID,
                isActive: false,
            })
        )
    }, [])

    return menu ? (
        <StyledLayerMenu
            as={motion.div}
            animate={{
                width: layer?.isActive ? '100vw' : '0vw',
            }}
            transition={{
                ease: 'easeOut',
                duration: 0.8,
                delay: layer?.isActive ? 0 : 0.4,
            }}
        >
            <motion.div
                animate={{
                    opacity: layer?.isActive ? 1 : 0,
                }}
                transition={{ delay: layer?.isActive ? 0.8 : 0, duration: 0.4 }}
                style={{ height: '100%' }}
            >
                <Scrollbar>
                    <FlexWrapper
                        css={{
                            height: '100%',
                            '@mdDown': {
                                padding: '150px 0 50px',
                                height: 'auto',
                                overflow: 'hidden',
                            },
                        }}
                        direction="column"
                        justify={{ '@initial': 'start', '@md': 'center' }}
                    >
                        <FlexWrapper
                            as={Container}
                            direction={{ '@initial': 'column', '@md': 'row' }}
                            justify={{ '@initial': 'start', '@md': 'between' }}
                        >
                            {menu.map(
                                ({ items }, index) =>
                                    items && (
                                        <MenuPanel key={index} big={index === 1}>
                                            <Padder
                                                top={{
                                                    '@initial': 'xs',
                                                    '@md': 'md',
                                                }}
                                                css={{
                                                    display: 'inline-block',
                                                    '@md': { textAlign: 'left' },
                                                }}
                                            >
                                                <Padder top="xs" as="ul">
                                                    {items.map(({ ID, url, title, children = [] }) => (
                                                        <Padder top="xxs" as="li" key={ID}>
                                                            <Text
                                                                as={Link}
                                                                to={url}
                                                                textVariant="titleSmall2"
                                                                onClick={children.length === 0 ? closeMenu : undefined}
                                                            >
                                                                {title}
                                                            </Text>
                                                            {children.length > 0 && (
                                                                <Padder as="ul" top="xxxs">
                                                                    {children.map(({ ID, url, title }) => (
                                                                        <li key={ID}>
                                                                            <Text
                                                                                as={Link}
                                                                                to={url}
                                                                                textVariant="titleSmall2"
                                                                                css={{
                                                                                    fontWeight: '$normal',
                                                                                    fontFamily: '$primary',
                                                                                }}
                                                                                onClick={closeMenu}
                                                                            >
                                                                                {title}
                                                                            </Text>
                                                                        </li>
                                                                    ))}
                                                                </Padder>
                                                            )}
                                                        </Padder>
                                                    ))}
                                                </Padder>
                                            </Padder>
                                        </MenuPanel>
                                    )
                            )}
                        </FlexWrapper>
                        {!responsiveInfo.md && <SocialWrapper>{/* <SiteLinks /> */}</SocialWrapper>}
                    </FlexWrapper>
                </Scrollbar>
            </motion.div>
        </StyledLayerMenu>
    ) : null
}

export default LayerMenu
