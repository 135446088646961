import { useResponsive } from 'ahooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import WebsiteContainer from 'src/components/_layout/WebsiteContainer'
import GlobalScroller from 'src/components/helpers/GlobalScroller/GlobalScroller'

import { getSettings } from 'src/selectors/settings'

import Header from './components/organisms/Header/Header'
import LayerMenu from './components/organisms/LayerMenu/LayerMenu'
import ScrollbarProvider from './context/scrollbar'
import RouterComponent from './router'
import { fetchMenu, fetchSettings } from './state/settings'
import { getLayerById } from './selectors/layers'
import { RootState } from './store'
import { AnimatePresence, motion } from 'framer-motion'

export default function App() {
    const dispatch = useDispatch()
    const { ready } = useSelector(getSettings)
    const responsiveInfo = useResponsive()
    const layerMenu = useSelector((state: RootState) => getLayerById(state, 'mainMenu'))

    useEffect(() => {
        dispatch(fetchSettings())
        dispatch(fetchMenu())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ready ? (
        <>
            <div className="App">
                <Router>
                    <ScrollbarProvider>
                        <GlobalScroller>
                            <Header />
                            {!responsiveInfo.md && <LayerMenu />}
                            <AnimatePresence initial={false}>
                                {!layerMenu?.isActive && (
                                    <WebsiteContainer
                                        as={motion.div}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            duration: 0.8,
                                            ease: 'easeOut',
                                        }}
                                    >
                                        <RouterComponent />
                                    </WebsiteContainer>
                                )}
                            </AnimatePresence>
                        </GlobalScroller>
                    </ScrollbarProvider>
                </Router>
            </div>
        </>
    ) : null
}
