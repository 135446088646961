import Button from 'src/components/atoms/Button/Button'
//import { Social } from 'src/components/molecules/SiteLinks/SiteLinks.style'
import { styled } from 'src/styles/stitches.config'

export const StyledHeader = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 5,
    paddingTop: '$sm',
    paddingBottom: '$sm',
    color: '$primary',

    [`${Button}`]: {
        borderColor: '$primary',
        color: '$primary',
        fontWeight: '$medium',
    },

    '.logo-svg': {
        fill: '$primary',
    },

    '&.light': {
        color: '$white',
        [`${Button}`]: {
            borderColor: '$white',
            color: '$white',
            fontWeight: '$medium',
        },

        // [`${Social}`]: {
        //     backgroundColor: '$white',
        //     color: '$brand',
        // },

        '.logo-svg': {
            fill: '$white',
        },
    },
})
