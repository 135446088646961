import Container from 'src/components/_layout/Container/Container'
import Text from 'src/components/atoms/Text/Text'
import { StyledPageTitle } from './PageTitle.style'
import type Stitches from '@stitches/react'
type PageTitleProps = {
    title: string
    css?: Stitches.CSS
}

const PageTitle: React.FC<PageTitleProps> = ({ title, css }) => {
    return (
        <StyledPageTitle>
            <Container>
                <Text as="h1" dangerouslySetInnerHTML={{ __html: title }} textVariant="heading1" css={css} />
            </Container>
        </StyledPageTitle>
    )
}

export default PageTitle
