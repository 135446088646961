import { styled } from 'src/styles/stitches.config'

export const StyledPageTitle = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100vh',
    paddingBottom: '$xl',
    minHeight: 780,
    '@md': {
        paddingBottom: '$lg',
    },
})

export const StyledInfos = styled('div', {
    whiteSpace: 'nowrap',

    img: {
        maxWidth: 70,
    },

    '.a-text': {
        width: '10rem',
        '&:last-child': {
            paddingLeft: '1rem',
        },
    },
})
