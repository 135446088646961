import { CommonFooter } from 'src/components/_composer'
import * as Styles from './Footer.style'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import { useSelector } from 'react-redux'
import { getFooter, getSettingsComponents } from 'src/selectors/settings'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Image from 'src/components/atoms/Image/Image'
import Container from 'src/components/_layout/Container/Container'
import Link from 'src/components/helpers/Link/Link'

import Text from 'src/components/atoms/Text/Text'
import { MenuItemType, MenuType } from 'src/state/settings/types'
import Padder from 'src/components/_layout/Padder/Padder'
import { useResponsive } from 'ahooks'

type FooterProps = {
    showStandardFooter?: boolean
    postType?: string
    pageId?: number
}

const getMenuItem = ({ ID, title, url }: MenuItemType) => (
    <li key={ID}>
        {
            <Link url={url} title={title}>
                <Text textVariant="small">{title}</Text>
            </Link>
        }
    </li>
)

const Footer: React.FC<FooterProps> = ({ showStandardFooter, postType, pageId }) => {
    const { logo, menu1, menu2, menu3, footerImages, disclaimer, footerImagesMobile } = useSelector(getFooter)
    const components = useSelector(getSettingsComponents)
    const responsiveInfo = useResponsive()
    return (
        <>
            {postType === 'artist' && <Composer components={[{ ...components['ArtistsSlider'], pageId }]} />}
            {postType === 'event' && (
                <Composer
                    components={[
                        {
                            ...components['EventsListing'],
                            title: 'Altri eventi',
                            pageId,
                        },
                    ]}
                />
            )}
            {showStandardFooter && <CommonFooter />}
            <Styles.Footer>
                <Container>
                    <Padder top="lg">
                        <FlexWrapper
                            justify={{ '@md': 'between' }}
                            direction={{ '@mdDown': 'column' }}
                            css={{ '@mdDown': { gap: '$sm' } }}
                        >
                            <Styles.FooterImage>
                                <Image src={logo.url} />
                            </Styles.FooterImage>
                            <div>
                                <FlexWrapper css={{ gap: '$sm' }} justify={{ '@mdDown': 'between' }}>
                                    {!!menu1 && <Styles.FooterMenu>{menu1?.items.map(getMenuItem)}</Styles.FooterMenu>}
                                    {!!menu2 && <Styles.FooterMenu>{menu2?.items.map(getMenuItem)}</Styles.FooterMenu>}
                                    {!!menu3 && <Styles.FooterMenu>{menu3?.items.map(getMenuItem)}</Styles.FooterMenu>}
                                </FlexWrapper>
                            </div>
                        </FlexWrapper>
                        <Image src={responsiveInfo.md ? footerImages.url : footerImagesMobile.url} />
                        <Text
                            css={{ fontSize: '0.8rem', a: { textDecoration: 'underline' } }}
                            align="center"
                            dangerouslySetInnerHTML={{ __html: disclaimer }}
                        />
                    </Padder>
                </Container>
            </Styles.Footer>
        </>
    )
}

export default Footer
