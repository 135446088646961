import { styled } from 'src/styles/stitches.config'

export const StyledRevealer = styled('div', {
    position: 'relative',
    opacity: 0,
    variants: {
        forceReveal: {
            true: {
                opacity: 1,
            },
        },
    },
})
