import { styled } from 'src/styles/stitches.config'

export const Footer = styled('div', {
    paddingTop: '$lg',
    paddingBottom: '$lg',
    backgroundColor: '#ffffff',
})

export const FooterMenu = styled('ul', {
    listStyle: 'none',
    paddingLeft: 0,
    li: {
        marginTop: '$xxs',
    },
})

export const FooterImage = styled('div', {
    '@mdDown': {
        display: 'flex',
        justifyContent: 'center',
    },
})
