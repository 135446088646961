import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    '.swiper-slide': {
        width: 'calc($colWidth * 6)',
    },
    '@mdDown': {
        '.swiper-slide': {
            width: '100%',
        },
    },
})

export const NavButton = styled('button', {
    '@mdDown': {
        display: 'block',
        width: '5rem',
        marginBottom: '$xs',
    },
    '@md': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'calc(1.4 * $colWidth)',
    },
})
