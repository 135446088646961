import { styled } from 'src/styles/stitches.config'

export const ArtistItem = styled('div', {
    width: '100%',
    '@sm': {
        width: '50%',
    },
    '@md': {
        width: 'calc(33.33333% - $sm)',
    },
})
