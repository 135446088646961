import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import * as Styles from './ContentWithImage.style'
import { CmsImage } from 'src/types'
import RichText from 'src/components/atoms/RichText/RichText'
import Container from 'src/components/_layout/Container/Container'
import Image from 'src/components/atoms/Image/Image'
import Padder from 'src/components/_layout/Padder/Padder'

type ContentWithImageProps = {
    content: string
    image: CmsImage
    imageDescription: string
}

const ContentWithImage: React.FC<ContentWithImageProps> = ({
    content,
    image,
    imageDescription,
}) => {
    return (
        <Container>
            <FlexWrapper
                css={{
                    flexDirection: 'column',
                    '@md': {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    },
                }}
            >
                <Styles.Content>
                    <RichText htmlString={content} />
                </Styles.Content>
                <Styles.Image>
                    <Padder top="xs">
                        <Image {...image} />
                        <RichText htmlString={imageDescription} />
                    </Padder>
                </Styles.Image>
            </FlexWrapper>
        </Container>
    )
}

export default ContentWithImage
