import { styled } from 'src/styles/stitches.config'
import { TicketsWrapper } from '../TicketsBanner/TicketsBanner.style'

export const BannerWrapper = styled('div', {
    [`& + ${TicketsWrapper}`]: {
        marginTop: '0!important',
    },
})

export const StyledImageWrapper = styled('div', {
    position: 'relative',
})
