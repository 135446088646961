import { styled } from 'src/styles/stitches.config'

export const ImageWrapper = styled('div', {
    position: 'relative',
})

export const Subtitle = styled('div', {
    '@mdDown': {
        padding: '$xs 0',
    },
    '@md': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        padding: '$xxs $xxs $xxs 0',
        backgroundColor: '$brand',
    },
})

export const Location = styled('div', {
    '@md': {
        position: 'absolute',
        bottom: '$colWidth',
        right: '$colWidth',
        zIndex: 1,
        color: '$white',
        width: '$colWidth',
        textAlign: 'center',
    },
    '@mdDown': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '$xs',
        alignItems: 'center',
        textAlign: 'center',
    },
})
