import { styleConstants, styled } from 'src/styles/stitches.config'

export const StyledGallery = styled('div', {
    position: `relative`,
    overflowX: 'hidden',
    '.swiper-slide': {
        width: '100%',
        transition: 'opacity .3s linear',
        height: '50vh',

        '> img': {
            width: 'auto',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
        },
        '@sm': {
            width: 'auto',
            height: '70vh',
        },
    },
})

export const NavButton = styled('button', {
    img: {
        width: '100px',
        height: 'auto',
    },
})
