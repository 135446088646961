import Container from 'src/components/_layout/Container/Container'
import * as Styles from './TicketsBanner.style'
import { CmsImage, CmsLink } from 'src/types'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text/Text'
import Padder from 'src/components/_layout/Padder/Padder'
import Image from 'src/components/atoms/Image/Image'
import Button from 'src/components/atoms/Button/Button'
import { Link } from 'react-router-dom'

type TicketsBannerProps = {
    firstLabel: string
    secondLabel: string
    thirdLabel: string
    logo: CmsImage
    link?: CmsLink
}

const TicketsBanner: React.FC<TicketsBannerProps> = ({ firstLabel, secondLabel, thirdLabel, logo, link }) => {
    return (
        <Styles.TicketsWrapper>
            <Container>
                <Padder top={{ '@initial': 'xxs', '@md': 'lg' }}>
                    <FlexWrapper
                        justify={{ '@md': 'between' }}
                        direction={{ '@mdDown': 'column' }}
                        css={{ '@mdDown': { gap: '$xxs', textAlign: 'center' } }}
                    >
                        <Text dangerouslySetInnerHTML={{ __html: firstLabel }} textVariant="titleSmall2" />
                        <Text dangerouslySetInnerHTML={{ __html: secondLabel }} textVariant="titleSmall2" />
                        <Text dangerouslySetInnerHTML={{ __html: thirdLabel }} textVariant="titleSmall2" />
                    </FlexWrapper>
                    {(!!logo || !!link) && (
                        <FlexWrapper
                            justify={{ '@md': 'between' }}
                            direction={{ '@mdDown': 'column' }}
                            align={{ '@initial': 'center', '@md': 'end' }}
                            css={{ '@mdDown': { gap: '$xs', img: { maxWidth: '120px!important' } } }}
                        >
                            {!!logo && <Image src={logo.url} alt={logo.alt} />}
                            {!!link && (
                                <div>
                                    <a href={link.url} target={link.target}>
                                        <Button variant="big" color="brand">
                                            {link.title}
                                        </Button>
                                    </a>
                                </div>
                            )}
                        </FlexWrapper>
                    )}
                </Padder>
            </Container>
        </Styles.TicketsWrapper>
    )
}

export default TicketsBanner
