import { styleConstants, styled } from 'src/styles/stitches.config'

export const StyledLogo = styled('div', {
    position: 'relative',
    width: `${styleConstants.colWidth * 1.7}vw`,
    minWidth: 100,
    img: {
        width: '100%',
        height: 'auto',
    },
})
