import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {})

export const ImageWrapper = styled('div', {
    '@lg': {
        img: { width: '100%' },
    },
    variants: {
        columns: {
            3: {
                width: '100%',
                '@lg': {
                    width: 'calc(33.333333% - $xs)',
                },
            },
            4: {
                width: 'calc(50% - $xxs)',
                '@lg': {
                    width: 'calc(25% - $xs)',
                },
            },
            5: {
                width: 'calc(50% - $xxs)',
                '@lg': {
                    width: 'calc(20% - $xs)',
                },
            },
            6: {
                width: 'calc(50% - $xxs)',
                '@lg': {
                    width: 'calc(16.66667% - $xs)',
                },
            },
        },
    },
})
